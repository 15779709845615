import Header from '../components/Header/Header';
import PublicSPportalBenefitsSection from '../components/PublicSPportalBenefitsSection/PublicSPportalBenefitsSection'
import Footer from '../components/Footer/Footer';
import PublicSPportalMainSection from '../components/PublicSPportalMainSection/PublicSPportalMainSection';
import { useContactForm } from '../components/ContactSupport/ContactFormContext';
import ContactSupport from '../components/ContactSupport/ContactSupport';


function  PublicSPportalPage() {
    const { isContactFormOpen, type, userRole} = useContactForm();
    return (
        <div className="App">
            <Header />
            <PublicSPportalMainSection/>
            <PublicSPportalBenefitsSection/>
            <Footer />
            {isContactFormOpen && <ContactSupport type={type} userRole={userRole} />}
        </div>
    );
}

export default  PublicSPportalPage;