import React, { useState, useEffect } from 'react';
import './AboutExplore.css';
import '../../index.css';
import { useContactForm } from '../ContactSupport/ContactFormContext';

const AboutExplore = () => {
    const { handleOpenContactForm } = useContactForm();
    return (
        <div className='about-explore-section'>
            <div class="about-explore">
                <div className="about-explore-title">Explore FinBursa and discover a new era of global investment opportunities</div>
                <button className="global-button" onClick={() => handleOpenContactForm("demo", "")}>Book a demo</button>
            </div>
        </div>

    );
};

export default AboutExplore;