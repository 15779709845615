import Header from '../components/Header/Header';
import AboutUsMainCover from '../components/AboutUsMainCover/AboutUsMainCover'
import AboutMissionSection from '../components/AboutMissionSection/AboutMissionSection'
import AboutChallenges from '../components/AboutChallenges/AboutChallenges'
import AboutWhoWeAre from '../components/AboutWhoWeAre/AboutWhoWeAre'
import AboutExplore from '../components/AboutExplore/AboutExplore'
import Footer from '../components/Footer/Footer';
import { useContactForm } from '../components/ContactSupport/ContactFormContext';
import ContactSupport from '../components/ContactSupport/ContactSupport';
import '../index.css'


function AboutUsPage() {
    const { isContactFormOpen, type, userRole} = useContactForm();
    return (
        <div className="App">
            <Header />
            <AboutUsMainCover/>
            <AboutMissionSection/>
            <div className='about-us-image'></div>
            <AboutChallenges/>
            <AboutWhoWeAre/>
            <AboutExplore/>
            <Footer />
            {isContactFormOpen && <ContactSupport type={type} userRole={userRole} />}
        </div>
    );
}

export default AboutUsPage;
