import React from 'react';
import { useParams } from 'react-router-dom';
import Header from '../components/Header/Header';
import PublicPageMainSection from '../components/PublicPageMainSection/PublicPageMainSection';
import PublicPageBenefitsSection from '../components/PublicPageBenefitsSection/PublicPageBenefitsSection'
import Footer from '../components/Footer/Footer';
import { ReactComponent as PublicSp } from '../assets/svg/publicSP.svg';
import { ReactComponent as PublicInvestor } from '../assets/svg/publicInvestor.svg';
import { ReactComponent as publicIssuer } from '../assets/svg/publicIssuer.svg';
import { ReactComponent as PublicAdvisor } from '../assets/svg/publicAdvisor.svg';
import '../index.css';
import { useContactForm } from '../components/ContactSupport/ContactFormContext';
import ContactSupport from '../components/ContactSupport/ContactSupport';

const PublicSPpage = () => {
    const { role } = useParams();
    const { isContactFormOpen, type, userRole, handleOpenContactForm } = useContactForm();

    const roleData = {
        'strategic-partner': {
            title: 'FinBursa 360 Solution',
            subtitle: (
                <>
                    Join the Future of Finance  <br />
                    with FinBursa 360
                </>
            ),
            text: 'Partner with FinBursa 360 to elevate your investment platform. Launch your branded investment portal, manage your deal pipeline, and streamline deal management—all in one place. Connect seamlessly with FinBursa\'s global marketplace and unlock cross-border opportunities through our trusted network. Strengthen client retention with robust deal management tools and real-time insights. Onboarding is effortless with our integrated KYC and AML solutions.',
            buttonLabel: 'Book a Demo',
            image: PublicSp,
            onClick: () => {
                // sessionStorage.setItem('userType', 'STRATEGIC_PARTNER');
                // window.location.href = '/registration/sp';
                handleOpenContactForm('demo', 'strategic partner')
            },
            className: 'global-button'
        },
        investor: {
            title: 'Investor',
            subtitle: (
                <>
                    Discover Diverse <br />
                    Investment Opportunities <br />
                    with FinBursa
                </>
            ),
            text: 'Join a global community of professional investors and access, cross-asset opportunities from industries across the world. Break free from the traditional barriers, reduce market entry cost and optimize your investment strategies.',
            buttonLabel: 'Pre-Register',
            image: PublicInvestor,
            onClick: () => {
                // sessionStorage.setItem('userType', 'INVESTOR');
                // window.location.href = '/registration/investor';

                handleOpenContactForm('pre-register', 'investor')

            },
            className: 'global-button-coming-soon'
        },
        issuer: {
            title: 'Issuer',
            subtitle: (
                <>
                    Raise Capital <br />
                    Globally with Ease
                </>
            ),
            text: 'FinBursa empowers issuers to connect with a global network of qualified investors and financial institutions. Utilize advanced fintech tools to streamline your capital-raising process, optimize costs, and ensure secure, scalable, and efficient management of your offerings.',
            buttonLabel: 'Pre-Register',
            image: publicIssuer,
            onClick: () => {
                // sessionStorage.setItem('userType', 'ISSUER');
                // window.location.href = '/registration/issuer';


                handleOpenContactForm('pre-register', 'issuer')
            },
            className: 'global-button-coming-soon'
        },
        advisor: {
            title: 'Consultant',
            subtitle: (
                <>
                    Empower Your Consultancy <br />
                    Business with FinBursa
                </>
            ),
            text: 'Join FinBursa as a qualified Consultant and unlock a world of opportunities. Connect with Investors and like-minded individuals, enhance your reach and explore new ways to grow your business, all within one platform.',
            buttonLabel: 'Pre-Register',
            image: PublicAdvisor,
            onClick: () => {
                // sessionStorage.setItem('userType', 'ADVISOR');
                // window.location.href = '/registration/advisor';

                handleOpenContactForm('pre-register', 'advisor')
            },
            className: 'global-button-coming-soon'
        },
    };

    const benefitsData = {
        'strategic-partner': {
            signuptext: 'Ready to Transform Your Business?',
            onClick: () => {
                // sessionStorage.setItem('userType', 'STRATEGIC_PARTNER');
                // window.location.href = '/registration/sp';
                handleOpenContactForm('demo', 'strategic partner')
            },
            benefits: [
                { text: 'Expand Your Network', subtext: 'Attract new clients and tap into broader markets.' },
                { text: 'Branded Solutions', subtext: 'Own a customizable platform tailored to your needs.' },
                { text: 'Efficiency Gains', subtext: 'Automate compliance and accelerate investor onboarding with integrated RegTech and KYC solutions.' },
                { text: 'Innovation Made Easy', subtext: 'Streamline deal execution with integrated Virtual Data Rooms (VDR), investment CRM, and automated deal workflows.' },
                { text: 'Boost Client Loyalty', subtext: 'Enhance retention with data-driven insights and diverse offerings.' }
            ]
        },
        'investor': {
            onClick: () => {
                // sessionStorage.setItem('userType', 'INVESTOR');
                // window.location.href = '/registration/investor';
                handleOpenContactForm('pre-register', 'investor')
            },
            signuptext: 'Ready to Transform Your Businessr?',
            benefits: [
                { text: 'Diverse Opportunities', subtext: 'Access investments across multiple industries, assets, and jurisdictions.' },
                { text: 'Global Reach', subtext: 'Explore markets worldwide with no jurisdiction limitations.' },
                { text: 'Investor Community', subtext: 'Engage with a network of like-minded professional investors.' },
                { text: 'Cost Saving', subtext: 'Breaks the shackles of banker-dependency through direct access.' },
            ]
        },
        'issuer': {
            onClick: () => {
                // sessionStorage.setItem('userType', 'ISSUER');
                // window.location.href = '/registration/issuer';
                handleOpenContactForm('pre-register', 'issuer')
            },
            signuptext: 'Start Raising Capital Today.',
            benefits: [
                { text: 'Global Reach', subtext: 'Exposure to a curated pool of investors and institutions across borders.' },
                { text: 'Integrated Tools', subtext: 'Manage your issuer posting securely and efficiently on one platform.' },
                { text: 'Cost Optimization', subtext: 'Gain affordable access to advisory services.' },
                { text: 'Real-Time Insights', subtext: 'Track your issuer posting progress and gauge market appetite instantly.' },
            ]
        },
        'advisor': {
            onClick: () => {
                // sessionStorage.setItem('userType', 'ADVISOR');
                // window.location.href = '/registration/advisor';
                handleOpenContactForm('pre-register', 'advisor')
            },
            signuptext: 'Join the Consultant Network Today',
            benefits: [
                { text: 'Expand Your Reach', subtext: 'Access a global network of investors and institutions.' },
                { text: 'Streamlined Operations', subtext: 'Manage processes and communications with your clients in one secure channel.' },
                { text: 'Monetize Your Expertise', subtext: 'Unlock new revenue streams and expand your business by leveraging FinBursa’s ecosystem.' },
            ]
        }
    };




    const data = roleData[role] || roleData['strategic-partner'];
    const roleBenefits = benefitsData[role] || benefitsData['strategic-partner'];
    return (
        <div className="App">
            <Header />
            <PublicPageMainSection data={{ ...data, onSignupClick: data.onClick }} />
            <PublicPageBenefitsSection
                benefits={roleBenefits.benefits}
                signuptext={roleBenefits.signuptext}
                onSignupClick={roleBenefits.onClick}
            />
            <Footer />
            {isContactFormOpen && <ContactSupport type={type} userRole={userRole} />}
        </div>
    );
};

export default PublicSPpage;


