import React, { useState, useEffect } from 'react';
import './MarketplaceMainSection.css';
import '../../index.css';
import useScreenSize from '../../hooks/useScreenSize';
import { useContactForm } from '../ContactSupport/ContactFormContext';

const MarketplaceMainSection = () => {
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const { isMobile, isTablet } = useScreenSize();
    const { handleOpenContactForm } = useContactForm();
    const togglePopup = () => {
        setIsPopupOpen((prev) => !prev);
    };

    const handleOverlayClick = (e) => {
        if (e.target.classList.contains('video-popup')) {
            setIsPopupOpen(false);
        }
    };

    const handleNavigation = () => {
        window.location.href = "/roles";
    };

    return (
        <div className="marketplace-main-cover">
            <div className="cover-content">
                <p className="marketplace-main-section-title ">Marketplace</p>
                <p className="marketplace-main-section-text">Where Opportunities <br/> Meet Capital</p> <br />
               
                <div className="button-section">
                    <button className="global-button btn" onClick={handleNavigation}>Join Now</button>
                </div>
            </div>
            

        </div>
    );
};

export default MarketplaceMainSection;
