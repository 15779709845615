import Header from '../components/Header/Header';
import MarketplaceMainSection from '../components/MarketplaceMainSection/MarketplaceMainSection'
import Footer from '../components/Footer/Footer';
import MarketplaceBenefitsSection from '../components/MarketplaceBenefitsSection/MarketplaceBenefitsSection';
import { useContactForm } from '../components/ContactSupport/ContactFormContext';
import ContactSupport from '../components/ContactSupport/ContactSupport';


function MarketplacePage() {
    const { isContactFormOpen, type, userRole} = useContactForm();
    return (
        <div className="App">
            <Header />
            <MarketplaceMainSection/>
            <MarketplaceBenefitsSection/>
            <Footer />
            {isContactFormOpen && <ContactSupport type={type} userRole={userRole} />}
        </div>
    );
}

export default MarketplacePage;