import React from 'react';
import '../../index.css';
import { ReactComponent as SPIcon1 } from '../../assets/svg/sp-icon1.svg';
import { ReactComponent as SPIcon2 } from '../../assets/svg/sp-icon2.svg';
import { ReactComponent as SPIcon3 } from '../../assets/svg/marketplace-icon3.svg';
import { ReactComponent as SPIcon4 } from '../../assets/svg/sp-icon4.svg';
import { ReactComponent as SpImage } from '../../assets/svg/sp.svg';
import { useContactForm } from '../ContactSupport/ContactFormContext';

const PublicSPportalBenefitsSection = ({ benefits, signuptext }) => {
    const { handleOpenContactForm } = useContactForm();

    return (
        <div className='marketplace-benefits-page'>
            <div className='marketplace-container'>
                <div className="marketplace-benefits-section">
                    <SPIcon1 />
                    <p className='marketplace-title'>White-Labeled  <br /> Client Portal</p>
                    <p className='marketplace-text'>Create private, branded spaces for exclusive transactions.</p>
                </div>
                <div className="marketplace-benefits-section">
                    <SPIcon2 />
                    <p className='marketplace-title'>Seamless Deal  <br />Management</p>
                    <p className='marketplace-text'>Directly connect with clients with secure virtual data rooms and streamlined deal pipeline workflows.</p>
                </div>
                <div className="marketplace-benefits-section">
                    <SPIcon3 />
                    <p className='marketplace-title'>Global Opportunity <br /> Access</p>
                    <p className='marketplace-text'>Find and connect with opportunities worldwide.</p>
                </div>
                <div className="marketplace-benefits-section">
                    <SPIcon4 />
                    <p className='marketplace-title'>Privacy & <br /> Transparency</p>
                    <p className='marketplace-text'>Ensure confidentiality and trust throughout the investment process.</p>
                </div>
            </div>


            <div className='benefits-signup-section marketplace-section'>
                <div className="benefits-signup">
                    <div className="benefits-signup-title">Explore FinBursa and discover a new era of global investment opportunities</div>
                    <button className="global-button" onClick={() => handleOpenContactForm("demo", "")}>Book a demo</button>
                </div>
            </div>

            <div className='marketplace-about'>
                <div className="main-users-section">
                    <div className="main-users-texts">
                        <p className="main-title">FinBursa 360 Solution</p>
                        <p className="subtitle">FinBursa’s 360 Solution provides financial advisory firms, asset managers, and institutional investors with a secure, white-labeled platform designed to streamline deal and pipeline management, client engagement, and opportunity discovery. Featuring built-in Virtual Data Rooms, intuitive collaboration tools, and direct access to a global marketplace of private investment opportunities, the 360 Solution helps firms expand their reach, engage more effectively, and support client growth and diversification.</p>

                    </div>
                    <div className="SpImage-container">
                        <SpImage />
                    </div>
                </div>
            </div>

        </div>
    );
};


export default PublicSPportalBenefitsSection;