import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';
import RolesSection from '../components/RolesSection/RolesSection';
import { useContactForm } from '../components/ContactSupport/ContactFormContext';
import ContactSupport from '../components/ContactSupport/ContactSupport';

function RolesPage() {
    const { isContactFormOpen, type, userRole} = useContactForm();
    return (
        <div className="App">
            <Header />
            <RolesSection />
            <Footer />
            {isContactFormOpen && <ContactSupport type={type} userRole={userRole} />}
        </div>
    );
}

export default RolesPage;